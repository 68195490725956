import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  subtitle?: string
  title?: string
}

export const Poi = memo(function Poi({ image, subtitle, title }: Props) {
  return (
    <Container>
      {image ? <Image {...image} /> : null}
      <Wrapper>
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {title ? <Title>{title}</Title> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: calc(25% - 1.25rem);
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin-top: 1.5rem;
  padding-bottom: 36%;
  position: relative;
  &:nth-of-type(odd) {
    transform: translateY(3.75rem);
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: calc(50% - 0.375rem);
    margin-top: 0.75rem;
    &:nth-of-type(odd) {
      transform: translateY(1.875rem);
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 75%;
    &:nth-of-type(odd) {
      transform: none;
    }
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 1.875rem;
  right: 1.875rem;
  left: 1.875rem;
  z-index: 2;
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.0563rem;
  line-height: 36px;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
`
